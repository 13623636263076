@font-face {
  font-family: arab-font;
  src: url("./assets/arab_dances/ArabDances.ttf");
}
* {
  font-family: arab-font;
}

/* font -family */
.arab-font {
  font-family: arab-font;
}

.header-style {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* background color */
.lightgrey-bg {
  background-color: #1e1e1ef2;
  backdrop-filter: blur(3px);
}

.black-bg {
  background-color: black;
}

.content-bg {
  background-image: url("./assets//background.png");
}

/* color */
.color-white {
  color: white;
}

.color-green {
  color: green;
}

.color-violet {
  color: violet;
}

.color-red {
  color: red;
}

/* fontsize */

.font-size-50 {
  font-size: 50px;
}
.font-size-40 {
  font-size: 40px;
}

.font-size-35 {
  font-size: 35px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-23 {
  font-size: 23px;
}

/* margin */
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}

/* ::marker{
  color:red;
  font-size:30px;
} */

.ant-divider-horizontal {
  margin: 0px !important;
}

/* padding */
.ptb-30 {
  padding: 30px 0px;
}

/* cursor */
.pointer {
  cursor: pointer;
}

.card-hover:hover {
  border: 3px dotted white;
  box-shadow: 0px 9px 30px rgba(243, 255, 5, 0.725);
}

.ant-card .ant-card-body {
  padding: 15px 10px !important;
}

.ant-collapse .ant-collapse-content {
  background: transparent;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  display: none;
}
